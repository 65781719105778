import * as Wildfire from "../../layers/static/Wildfire";

import base, { Config, StaticMapLayer } from "../../config";
import { camerasAZGF, camerasNavajo } from "../../layers/misc/cameras";

import FEMA_NFHL from "../../layers/dynamic/FEMA_NFHL";
import { LatLng } from "leaflet";
import notEmpty from "../../shared/notEmpty";

const impoundment: StaticMapLayer =
  { name: `Impoundment`, source: `${process.env.PUBLIC_URL}/geojson/navajo-impoundment.geojson`}
const staticLayers: StaticMapLayer[] = [Wildfire.current()].filter(notEmpty).concat(impoundment)

const config: Config = {
  ...base,
  ...{
    title: "Navajo County ALERT",
    center: new LatLng(34.38, -110.49),
    zoom: 9,
    basemap: "Topographic",
    agencyPassList: [
      "Navajo County Flood Control District",
      "Arizona Game and Fish Department",
      "Arizona Department of Water Resources",
      "United States Geological Survey",
    ],
    layers: {
      static: staticLayers,
      dynamic: [FEMA_NFHL],
      misc: [
        {
          title: "Webcams",
          layers: [
            { name: "Navajo County", cameras: camerasNavajo },
            { name: "Arizona Game and Fish Department", cameras: camerasAZGF },
          ],
        },
      ],
      lightning: false,
      cbrfc: true
    },
  },
};

export default config;
